import React, { useState, ReactNode } from 'react';
import axios from 'axios';
import { HandHeart, Search, MapPin, Users, ArrowRight, Globe, Bell, User, Heart,
    Sparkles, Share2, TrendingUp, Shield, Package, Star, Clock, Building2,
    AlertTriangle, ChevronDown, Sun, Moon } from 'lucide-react';
import theme from "tailwindcss/defaultTheme";

type Language = 'en' | 'de' | 'es' | 'pt';

interface CardProps {
    className?: string;
    children: ReactNode;
}

interface MenuItem {
    label: string;
    href: string;
}

const translations = {
    en: {
        dashboard: 'Dashboard',
        community: 'Community',
        organizations: 'Organizations',
        share: 'Share',
        search: 'Search for resources or support needed...',
        searchButton: 'Search',
        connectShare: 'Connect • Share • Support',
        joinPlatform: 'Join our platform connecting those who need resources with those who can help',
        communityResources: 'Community Resources',
        availableItems: 'Available Items',
        items: 'items',
        timeBank: 'Time Bank',
        hours: 'hours',
        emergencyAid: 'Emergency Aid',
        support: '24/7 support',
        activeListings: 'Active Listings',
        viewAll: 'View All',
        connect: 'Connect',
        resourcesShared: 'Resources Shared',
        activeMembers: 'Active Members',
        organizationsCount: 'Organizations',
        successRate: 'Success Rate',
        menuOrganizations: 'Organizations'
    },
    de: {
        dashboard: 'Dashboard',
        community: 'Gemeinschaft',
        organizations: 'Organisationen',
        share: 'Teilen',
        search: 'Suche nach Ressourcen oder benötigter Unterstützung...',
        searchButton: 'Suchen',
        connectShare: 'Verbinden • Teilen • Unterstützen',
        joinPlatform: 'Treten Sie unserer Plattform bei, die Hilfesuchende mit Helfenden verbindet',
        communityResources: 'Gemeinschaftsressourcen',
        availableItems: 'Verfügbare Artikel',
        items: 'Artikel',
        timeBank: 'Zeitbank',
        hours: 'Stunden',
        emergencyAid: 'Nothilfe',
        support: '24/7 Unterstützung',
        activeListings: 'Aktive Angebote',
        viewAll: 'Alle anzeigen',
        connect: 'Verbinden',
        resourcesShared: 'Geteilte Ressourcen',
        activeMembers: 'Aktive Mitglieder',
        organizationsCount: 'Organisationen',
        successRate: 'Erfolgsrate',
        menuOrganizations: 'Organisationen'
    },
    es: {
        dashboard: 'Panel',
        community: 'Comunidad',
        organizations: 'Organizaciones',
        share: 'Compartir',
        search: 'Buscar recursos o apoyo necesario...',
        searchButton: 'Buscar',
        connectShare: 'Conectar • Compartir • Apoyar',
        joinPlatform: 'Únase a nuestra plataforma que conecta a quienes necesitan recursos con quienes pueden ayudar',
        communityResources: 'Recursos Comunitarios',
        availableItems: 'Artículos Disponibles',
        items: 'artículos',
        timeBank: 'Banco de Tiempo',
        hours: 'horas',
        emergencyAid: 'Ayuda de Emergencia',
        support: 'soporte 24/7',
        activeListings: 'Listados Activos',
        viewAll: 'Ver Todo',
        connect: 'Conectar',
        resourcesShared: 'Recursos Compartidos',
        activeMembers: 'Miembros Activos',
        organizationsCount: 'Organizaciones',
        successRate: 'Tasa de Éxito',
        menuOrganizations: 'Organizaciones'
    },
    pt: {
        dashboard: 'Painel',
        community: 'Comunidade',
        organizations: 'Organizações',
        share: 'Compartilhar',
        search: 'Procure por recursos ou suporte necessário...',
        searchButton: 'Pesquisar',
        connectShare: 'Conectar • Compartilhar • Apoiar',
        joinPlatform: 'Junte-se à nossa plataforma que conecta aqueles que precisam de recursos com aqueles que podem ajudar',
        communityResources: 'Recursos da Comunidade',
        availableItems: 'Itens Disponíveis',
        items: 'itens',
        timeBank: 'Banco de Tempo',
        hours: 'horas',
        emergencyAid: 'Ajuda de Emergência',
        support: 'suporte 24/7',
        activeListings: 'Listagens Ativas',
        viewAll: 'Ver Tudo',
        connect: 'Conectar',
        resourcesShared: 'Recursos Compartilhados',
        activeMembers: 'Membros Ativos',
        organizationsCount: 'Organizações',
        successRate: 'Taxa de Sucesso',
        menuOrganizations: 'Organizações'
    }
};

const Card: React.FC<CardProps> = ({ className = "", children }) => (
    <div className={`rounded-lg border ${theme.cardBg} ${theme.text} ${theme.cardShadow} ${className}`}>
        {children}
    </div>
);

const LanguageSelector: React.FC<{
    currentLang: Language;
    onLanguageChange: (lang: Language) => void;
}> = ({ currentLang, onLanguageChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const languages = { en: 'English', pt: 'Português', de: 'Deutsch', es: 'Español' };

    return (
        <div className="relative">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center space-x-2 text-gray-400 hover:text-green-400"
            >
                <Globe className="w-5 h-5" />
                <span>{languages[currentLang]}</span>
                <ChevronDown className="w-4 h-4" />
            </button>

            {isOpen && (
                <div
                    className={`absolute top-full right-0 mt-2 ${theme.dropdownBg} rounded-lg shadow-lg py-2 min-w-[120px]`}>
                    {Object.entries(languages).map(([code, name]) => (
                        <button
                            key={code}
                            onClick={() => {
                                onLanguageChange(code as Language);
                                setIsOpen(false);
                            }}
                            className={`w-full px-4 py-2 text-left ${theme.dropdownHover} ${
                                currentLang === code ? theme.accent : theme.text
                            }`}
                        >
                            {name}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

const ConnectingNeedsPlatform: React.FC = () => {
    const [activeTab, setActiveTab] = useState('dashboard');
    const [hoveredCard, setHoveredCard] = useState<number | null>(null);
    const [language, setLanguage] = useState<Language>('en');
    const [searchText, setSearchText] = useState('');
    const [aiResponse, setAiResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [isPoisonTheme, setIsPoisonTheme] = useState(false);
    const t = translations[language];

    const [isDarkMode, setIsDarkMode] = useState(true); // Rename from isPoisonTheme

    const theme = {
        primary: isDarkMode
            ? 'from-green-400 to-blue-400'
            : 'from-green-600 to-blue-600',
        background: isDarkMode
            ? 'bg-gray-900'
            : 'bg-gray-50',
        cardBg: isDarkMode
            ? 'bg-gray-800/50'
            : 'bg-white',
        text: isDarkMode
            ? 'text-white'
            : 'text-gray-900',
        lightText: isDarkMode
            ? 'text-white'
            : 'text-gray-700',
        border: isDarkMode
            ? 'border-gray-700'
            : 'border-gray-200',
        hover: isDarkMode
            ? 'hover:border-green-400'
            : 'hover:border-green-600',
        button: isDarkMode
            ? 'bg-gradient-to-r from-green-500 to-blue-500'
            : 'bg-gradient-to-r from-green-600 to-blue-600',
        secondaryText: isDarkMode
            ? 'text-gray-400'
            : 'text-gray-600',
        navBg: isDarkMode
            ? 'bg-gray-900/80'
            : 'bg-white/80',
        cardShadow: isDarkMode
            ? ''
            : 'shadow-md',
        inputBg: isDarkMode
            ? 'bg-gray-900/50'
            : 'bg-gray-100',
        accent: isDarkMode
            ? 'text-green-400'
            : 'text-green-600',
        dropdownBg: isDarkMode
            ? 'bg-gray-800'
            : 'bg-white',
        dropdownHover: isDarkMode
            ? 'hover:bg-gray-700'
            : 'hover:bg-gray-100',
        menuHover: isDarkMode
            ? 'hover:bg-gray-800/50'
            : 'hover:bg-gray-100',
        tagBg: isDarkMode
            ? 'bg-gray-800'
            : 'bg-gray-100',
        tagText: isDarkMode
            ? 'text-white'
            : 'text-gray-800',
        headerText: isDarkMode
            ? 'text-white'
            : 'text-gray-800',
        statValue: isDarkMode
            ? 'text-white'
            : 'text-gray-900',
        statLabel: isDarkMode
            ? 'text-gray-300'
            : 'text-gray-600'
    };

    const menuItems: MenuItem[] = [
        { label: t.dashboard, href: '#dashboard' },
        { label: t.community, href: '#community' },
        { label: t.organizations, href: '#organizations' },
        { label: t.share, href: '#share' }
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleSearch = async () => {
        if (!searchText.trim()) return;
        setLoading(true);
        try {
            const response = await axios.post('/api/openai', {
                prompt: searchText,
            });
            setAiResponse(response.data.text);
        } catch (error) {
            console.error('Error fetching AI response:', error);
            setAiResponse('Failed to get response. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`ConnectingNeedsPlatform min-h-screen ${theme.background} ${theme.text}`}>
            <nav className={`${theme.navBg} backdrop-blur-lg border-b ${theme.border} sticky top-0 z-50`}>
                <div className="max-w-7xl mx-auto px-4 py-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-8">
                            <div className="flex items-center space-x-2">
                                <div className="relative">
                                    <HandHeart
                                        className={`w-8 h-8 ${isPoisonTheme ? 'text-[#A3EA71]' : 'text-green-400'}`}/>
                                    <div
                                        className="absolute -top-1 -right-1 w-3 h-3 bg-blue-500 rounded-full animate-pulse"/>
                                </div>
                                <span
                                    className={`hidden md:flex text-xl font-bold bg-gradient-to-r ${theme.primary} text-transparent bg-clip-text`}>
                                    ConnectingNeeds
                                </span>
                            </div>
                            <div className="hidden md:flex items-center space-x-1">
                                {menuItems.map((item) => (
                                    <a
                                        key={item.href}
                                        href={item.href}
                                        className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                                            activeTab === item.href.slice(1)
                                                ? `${theme.cardBg} ${isPoisonTheme ? 'text-[#A3EA71]' : 'text-green-400'}`
                                                : `${theme.secondaryText} hover:bg-gray-800/50`
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab(item.href.slice(1));
                                        }}
                                    >
                                        {item.label}
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={() => setIsDarkMode(!isDarkMode)}
                                className={`p-2 rounded-lg ${theme.cardBg} ${theme.border} transition-colors ${theme.cardShadow}`}
                            >
                                {isDarkMode ? (
                                    <Sun className="w-5 h-5 text-yellow-400"/>
                                ) : (
                                    <Moon className="w-5 h-5 text-gray-600"/>
                                )}
                            </button>
                            <LanguageSelector
                                currentLang={language}
                                onLanguageChange={setLanguage}
                            />
                            <div className="flex items-center">
                                <Star className="w-5 h-5 text-yellow-400"/>
                                <span className="ml-1 text-yellow-400">485</span>
                            </div>
                            <Bell
                                className={`w-5 h-5 ${theme.secondaryText} ${theme.hover} cursor-pointer transition-colors`}/>
                            <div
                                className={`w-8 h-8 ${theme.cardBg} rounded-full flex items-center justify-center hover:bg-gray-700 cursor-pointer transition-colors`}>
                                <User className={theme.secondaryText}/>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <div className={`relative ${theme.background} overflow-hidden`}>
            <div className="max-w-7xl mx-auto px-4 py-12 md:py-20 relative">
                    <div className="text-center">
                        <h1 className={`text-3xl md:text-5xl font-bold mb-4 md:mb-6 bg-gradient-to-r ${theme.primary} text-transparent bg-clip-text animate-gradient`}>
                            {t.connectShare}
                        </h1>
                        <p className={`text-lg md:text-xl ${theme.lightText} mb-6 md:mb-8 max-w-2xl mx-auto px-4`}>
                            {t.joinPlatform}
                        </p>
                        <div className="w-full max-w-2xl mx-auto px-4">
                        <div className={`${theme.cardBg} backdrop-blur-sm rounded-xl p-2 border ${theme.border}`}>
                                <div className="flex flex-col md:flex-row items-center gap-2">
                                    <div className={`flex items-center w-full ${theme.inputBg} rounded-lg px-2`}>
                                        <Search className={theme.secondaryText}/>
                                        <input
                                            type="text"
                                            placeholder={t.search}
                                            className={`flex-1 bg-transparent outline-none p-3 ${theme.text} placeholder:${theme.secondaryText}`}
                                            value={searchText}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <button
                                        onClick={handleSearch}
                                        disabled={loading}
                                        className={`w-full md:w-auto ${theme.button} text-white px-6 py-3 rounded-lg`}
                                    >
                                        {loading ? 'Searching...' : t.searchButton}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                <div
                    className={`ai-response-window mt-8 p-4 ${theme.cardBg} ${theme.text} max-w-2xl mx-auto rounded-lg`}>
                    {aiResponse && <p>{aiResponse}</p>}
                </div>
            </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 py-12">
                <h2 className={`text-2xl font-semibold mb-8 flex items-center ${theme.headerText}`}>
                    <Sparkles className={theme.accent}/>
                    {t.communityResources}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {[
                        {
                            icon: Package,
                            title: t.availableItems,
                            description: t.items,
                            stat: '45 items',
                            color: isDarkMode
                                ? 'from-green-500 to-emerald-700'
                                : 'from-green-400 to-emerald-600'
                        },
                        {
                            icon: Clock,
                            title: t.timeBank,
                            description: t.hours,
                            stat: '120 hours',
                            color: isDarkMode
                                ? 'from-blue-500 to-indigo-700'
                                : 'from-blue-400 to-indigo-600'
                        },
                        {
                            icon: AlertTriangle,
                            title: t.emergencyAid,
                            description: t.support,
                            stat: '24/7 support',
                            color: isDarkMode
                                ? 'from-red-500 to-pink-700'
                                : 'from-red-400 to-pink-600'
                        }
                    ].map((feature, index) => (
                        <div
                            key={feature.title}
                            className="relative group"
                            onMouseEnter={() => setHoveredCard(index)}
                            onMouseLeave={() => setHoveredCard(null)}
                        >
                            <Card className={`p-6 ${theme.cardBg} backdrop-blur-sm ${theme.border} ${theme.hover} transition-all duration-300`}>
                                <div className={`absolute inset-0 bg-gradient-to-br ${feature.color} opacity-0 group-hover:opacity-10 transition-opacity rounded-lg`}/>
                                <div className="relative">
                                    <feature.icon
                                        className={`w-8 h-8 mb-4 ${
                                            hoveredCard === index
                                                ? theme.accent
                                                : theme.secondaryText
                                        } transition-colors`}
                                    />
                                    <h3 className={`font-semibold text-lg mb-2 ${theme.text}`}>
                                        {feature.title}
                                    </h3>
                                    <p className={`${theme.lightText} mb-4`}>
                                        {feature.description}
                                    </p>
                                    <div className="flex items-center justify-between">
                    <span className={`text-sm ${theme.lightText}`}>
                        {feature.stat}
                    </span>
                                        <ArrowRight
                                            className={`w-5 h-5 ${theme.accent} transform group-hover:translate-x-1 transition-transform`}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>
            </div>

            <div className={`${theme.cardBg} py-12 mt-12`}>
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between items-center mb-8">
                        <h2 className={`text-2xl font-semibold flex items-center ${theme.headerText}`}>
                            <Share2 className={`w-6 h-6 mr-2 ${theme.accent}`}/>
                            {t.activeListings}
                        </h2>
                        <button className={`${theme.accent} hover:opacity-80 flex items-center group`}>
                            {t.viewAll}
                            <ArrowRight
                                className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform"/>
                        </button>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {[
                            {
                                title: t.availableItems,
                                location: "North London",
                                type: "Community Share",
                                category: t.items,
                                sharedBy: "Sarah M.",
                                timePosted: "5 minutes ago",
                                trustScore: "4.9"
                            },
                            {
                                title: t.timeBank,
                                location: "Manchester",
                                type: "Time Bank",
                                category: t.hours,
                                sharedBy: "James K.",
                                timePosted: "12 minutes ago",
                                trustScore: "4.7"
                            },
                            {
                                title: t.emergencyAid,
                                location: "Birmingham",
                                type: "Organization Aid",
                                category: "Food",
                                sharedBy: "Local Food Bank",
                                timePosted: "2 minutes ago",
                                verified: true
                            }
                        ].map((listing) => (
                            <Card
                                key={listing.title}
                                className={`group ${theme.cardBg} ${theme.border} ${theme.hover} transition-all duration-300`}
                            >
                                <div className="p-6">
                                    <div className="space-y-4">
                                        <div>
                                            <h3 className={`font-semibold text-lg mb-2 ${theme.text} group-hover:${isPoisonTheme ? 'text-[#A3EA71]' : 'text-green-400'} transition-colors`}>
                                                {listing.title}
                                            </h3>
                                            <div className={`flex items-center ${theme.lightText} mb-2`}>
                                                <MapPin className="w-4 h-4 mr-2"/>
                                                {listing.location}
                                            </div>
                                        </div>
                                        <div className="flex space-x-2">
                                            <span
                                                className={`${theme.tagBg} ${theme.tagText} text-sm px-3 py-1 rounded-full border ${
                                                    listing.type === 'Community Share'
                                                        ? isDarkMode ? 'border-green-700' : 'border-green-500'
                                                        : listing.type === 'Time Bank'
                                                            ? isDarkMode ? 'border-blue-700' : 'border-blue-500'
                                                            : isDarkMode ? 'border-red-700' : 'border-red-500'
                                                }`}>
    {listing.type}
</span>
                                            <span
                                                className={`${theme.tagBg} ${theme.tagText} text-sm px-3 py-1 rounded-full border ${theme.border}`}>
    {listing.category}
</span>
                                        </div>
                                        <div className={`flex items-center ${theme.lightText} text-sm justify-between`}>
                                            <div className="flex items-center">
                                                <User className="w-4 h-4 mr-2"/>
                                                {listing.sharedBy}
                                                {listing.trustScore && (
                                                    <>
                                                        <Star className="w-4 h-4 ml-2 mr-1 text-yellow-400"/>
                                                        {listing.trustScore}
                                                    </>
                                                )}
                                                {listing.verified && <Shield
                                                    className={`w-4 h-4 ml-2 ${isPoisonTheme ? 'text-[#A3EA71]' : 'text-green-400'}`}/>}
                                            </div>
                                            <span className={theme.secondaryText}>{listing.timePosted}</span>
                                        </div>
                                        <button className={`w-full ${theme.button} text-white px-4 py-2 rounded-lg hover:from-green-600 hover:to-blue-600 transition-all transform hover:scale-105`}>
                                            {t.connect}
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 py-16">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {[
                        {value: "12,345", label: t.resourcesShared},
                        {value: "5,678", label: t.activeMembers},
                        {value: "890", label: t.organizations},
                        {value: "94%", label: t.successRate}
                    ].map((stat) => (
                        <Card key={stat.label} className={`p-6 ${theme.cardBg} backdrop-blur-sm ${theme.border} ${theme.hover} transition-all group`}>
                            <div className={`text-3xl font-bold bg-clip-text bg-gradient-to-r ${theme.primary} mb-2`}>
                                {stat.value}
                            </div>
                            <div className={`${theme.statLabel} group-hover:${theme.lightText} transition-colors`}>
                                {stat.label}
                            </div>
                        </Card>
                    ))}
                </div>
            </div>

            <footer className={`${theme.background} ${theme.text} py-6 mt-auto w-full text-center sticky bottom-0`}>
                <div className="max-w-7xl mx-auto px-4">
                    <p className="text-sm md:text-base">
                        This platform was created as part of the MIT Leadership & Innovation program, aimed at driving community impact through technology. We strive to connect individuals and organizations to create sustainable solutions for real-world challenges.
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default ConnectingNeedsPlatform;